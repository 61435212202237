<template>
	<div class="apply-detail-container">
		<div class="apply-detail-container-top">
			<van-nav-bar title="订单详情" fixed />
		</div>
		<div class="apply-detail-container-content">
			<van-cell-group>
				<van-cell title="订单编号" :value="order.bill_code" />
				<van-cell title="订单类型" :value="order.billTypeName" />
				<van-cell title="订单状态" :value="order.bill_status_name" />
				<van-cell title="下单时间" :value="order.createTime" />
				<van-cell title="配送时间" :value="order.send_time" />
				<van-cell title="配送站" :value="order.distribution_name" />
				<van-cell title="配送员" :value="order.distribution_technicia" />
				<van-cell title="小区" :value="order.small_area_name" />
				<van-cell title="收货人" :value="order.deliver_name" />
				<van-cell title="手机号" :value="order.deliver_mobile" />
				<van-cell title="收货地址" :value="order.deliver_city+order.deliver_county+order.deliver_address" />
				<van-cell title="付款方式" :value="order.payTypeName" />
				<van-cell title="应收金额" :value="order.bill_receivable_money" />
				<van-cell title="配送备注" :value="order.remark" />
				<div class="apply-detail-container-btn">
					<!-- 每个元素的两侧间隔相等 -->
					<van-row type="flex" justify="space-around">
						<van-col span="8">
							<van-button square type="info" icon="success" size="small" text="分配修改" @click="onAllocation" />
						</van-col>
						<van-col span="8">
							<van-button square type="primary" icon="edit" size="small" text="分单调度" @click="onDispatch" />
						</van-col>
					</van-row>
				</div>
			</van-cell-group>
		</div>
		<!-- 弹出区域 -->
		<div class="apply-detail-container-popup">
			<van-popup v-model="picker.allocation" position="right" :round="true" :style="{width:'100%',height:'20%'}">
				<van-cell-group>
					<van-field v-model="info.distribution_technicia" label="配送员" placeholder="请选择配送员" style="margin-top: 2rem;"
						@focus="picker.technician=true" readonly />
					<van-button type="info" icon="passed" size="large" @click="saveAllocation"
						style="position:fixed;bottom:0;" text="确定" />
				</van-cell-group>
			</van-popup>
			<van-popup v-model="picker.dispatch" position="right" :round="true" :style="{width:'100%',height:'20%'}">
				<van-cell-group>
					<van-field v-model="info.distribution_name" label="配送站" placeholder="请选择配送站" style="margin-top: 2rem;"
						@focus="picker.distribution=true" readonly />
					<van-button type="info" icon="passed" size="large" @click="saveDispatch"
						style="position:fixed;bottom:0;" text="确定" />
				</van-cell-group>
			</van-popup>
			<!-- 配送站 -->
			<van-popup v-model="picker.distribution" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送站"
			            :columns="distributionList"
			            @confirm="onChangeDistribution"
			            @cancel="picker.distribution=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 配送员 -->
			<van-popup v-model="picker.technician" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送员"
			            :columns="technicianList"
			            @confirm="onChangeTechnician"
			            @cancel="picker.technician=false"
			            :show-toolbar="true"
			    />
			</van-popup>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'apply-detail',
		props: ['param'],
		data() {
			return {
				auth: this.$store.state.user.auth || [],
				order: {},
				info: {},
				technicianList: [],
				distributionList: [],
				picker: {
					allocation: false,
					dispatch: false,
					technician: false,
					distribution: false
				}
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			// 初始化
			init() {
				let that = this;
				if (that.param && that.param.id > 0) {
					that.$api.allocation.detail(that.param.id).then(response => {
					    let res = response.data;
					    if (res.code == 1) {
							that.order = res.data;
							that.info = {};
							that.info.ids = [that.param.id];
							that.info.city = that.order.deliver_cityid;
							that.info.distribution = that.order.distribution_id;
							that.$api.user.loadSiteAll(that.order.deliver_cityid).then(response => {
							    let res = response.data;
							    if (res.code == 1 && res.data.length > 0) {
							        res.data.unshift({index: 0, text: '请选择'});
							        that.distributionList = res.data;
							    }
							});
							that.$api.user.loadCourier(that.order.distribution_id).then(response => {
							    let ret = response.data;
							    if (ret.code == 1 && ret.data.length > 0) {
							        ret.data.unshift({index: 0, text: '请选择'});
							        that.technicianList = ret.data;
							    }
							});
					    } else {
					        that.$toast.fail(res.msg);
					    }
					});
				}
			},
			// 分配修改
			onAllocation() {
				this.picker.allocation = true;
			},
			// 分单调度
			onDispatch() {
				this.picker.dispatch = true;
			},
			// 分配修改保存
			saveAllocation() {
				let that = this;
				if (!that.info.distribution_technician_id) {
					return that.$toast.fail('请选择配送员');
				}
				that.$dialog.confirm({
				    message: '确认分配配送员为' + that.info.distribution_technicia + '吗？'
				}).then(() => {
				    that.$api.allocation.allocation(that.info).then(response => {
				        let res = response.data;
				        if (res.code == 1) {
				            that.$toast.success(res.msg);
				            that.init();
				    		that.picker.allocation = false;
				        } else {
				            that.$toast.fail(res.msg);
				        }
				    });
				});
			},
			// 分单调度保存
			saveDispatch() {
				let that = this;
				if (!that.info.distribution_id) {
					return that.$toast.fail('请选择配送站');
				}
				that.$dialog.confirm({
				    message: '确认分单调度到' + that.info.distribution_name + '吗？'
				}).then(() => {
				    that.$api.allocation.dispatch(that.info).then(response => {
				        let res = response.data;
				        if (res.code == 1) {
				            that.$toast.success(res.msg);
				            that.init();
				    		that.picker.dispatch = false;
				        } else {
				            that.$toast.fail(res.msg);
				        }
				    });
				});
			},
			onChangeTechnician(value) {
				this.picker.technician = false;
				this.info.distribution_technician_id = value.index;
				this.info.distribution_technicia = value.text;
			},
			onChangeDistribution(value) {
				this.picker.distribution = false;
				this.info.distribution_id = value.index;
				this.info.distribution_name = value.text;
			}
		}
	}
</script>

<style scoped>
	.apply-detail-container {
		background-color: #f5f5f5;
		height: 100%;
		width: 100%;
	}

	.apply-detail-container-content {
		position: fixed;
		top: 46px;
		bottom: 0;
		overflow-y: scroll;
		left: 0;
		right: 0;
	}

	.apply-detail-container-btn {
		text-align: center;
		background-color: #fff;
		padding: 10px 16px;
	}

	.van-cell__value {
		flex: 4
	}

	.apply-goods {
		text-align: center;
		border-bottom: 1px solid #ebedf0;
		color: #323232;
		line-height: 30px;
		font-size: 10px;
	}

	.apply-goods-title {
		background: #f5f5f5;
	}

	.apply-goods-item {
		border-bottom: 1px dashed #ebedf0;
	}

	.apply-goods-item:last-child {
		border: none;
	}

	.no-bottom {
		bottom: 0;
	}
</style>