<template>
    <div class="order-search-container">
        <div class="order-search-container-top">
            <van-nav-bar title="订单分配" fixed/>
        </div>
        <div class="order-search-container-search">
            <van-search placeholder="搜索一下" :disabled="true" show-action @click="picker.search=true">
               <div slot="action" @click="onAll">分配/调度</div>
            </van-search>
        </div>
        <div class="order-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
						
                        <van-cell :border="true" :value="item.distribution_name" :label="item.address" @click="onBill(item.bill_no)">
                            <template slot="title">
								<van-checkbox-group v-model="ids" direction="horizontal" style="float: left;">
								    <van-checkbox :key="index" :name="item.bill_no" shape="square"></van-checkbox>
								</van-checkbox-group>
                                <!--订单状态-->
                                <van-tag round plain type="primary" >
                                    {{item.bill_status_name}}
                                </van-tag>
								<!--配送员-->
								<van-tag round plain type="success" v-if="item.distribution_technicia">
								    {{item.distribution_technicia}}
								</van-tag>
								<van-tag round plain type="danger" v-else>
								    未分配
								</van-tag>
                                <span class="custom-title">{{item.bill_code}}</span><br>
								<span class="custom-title">{{item.send_time}}</span>
                            </template>
                        </van-cell>
                        <template slot="right">
                          <!-- <van-button square type="info" icon="success" text="修改" @click="allocation(item.bill_no)" v-if="item.distribution_technicia"/>
						   <van-button square type="info" icon="success" text="分配" @click="allocation(item.bill_no)" v-if="!item.distribution_technicia" />
						   <van-button square type="primary" icon="edit" text="调度" @click="dispatch(item.bill_no)" /> -->
						    <van-button square type="primary" icon="edit" text="详情" @click="detail(item)" />
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="order-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="order-search-container-popup">
            <!-- 搜索 -->
            <van-popup v-model="picker.search" position="right" :round="true" :style="{width:'80%',height:'100%'}">
                <van-cell-group>
                    <van-field
                            v-model="option.id"
                            label="订单编号"
                            placeholder="请输入订单编号"
                            clearable
                    />
                    <van-field
                            v-model="option.sendDate"
                            label="配送日期"
                            placeholder="请选择配送日期"
                            @focus="picker.sendDate=true"
                            readonly
                    />
                    <van-field
                            v-model="option.statusName"
                            label="订单状态"
                            placeholder="请选择订单状态"
                            @focus="picker.status=true"
                            readonly
                    />
					<van-field
					        v-model="option.typeName"
					        label="订单类型"
					        placeholder="请选择订单类型"
					        @focus="picker.type=true"
					        readonly
					/>
					<van-field
					        v-model="option.cityName"
					        label="城市"
					        placeholder="请选择城市"
					        @focus="picker.city=true"
					        readonly
					/>
					<van-field
					        v-model="option.distributionName"
					        label="配送站"
					        placeholder="请选择配送站"
					        @focus="picker.distribution=true"
					        readonly
					/>
					<van-field
					        v-model="option.technicianName"
					        label="配送员"
					        placeholder="请选择配送员"
					        @focus="picker.technician=true"
					        readonly
					/>
					<van-field
					        v-model="option.smallAreaName"
					        label="小区"
					        placeholder="请选择小区"
					        @focus="picker.smallArea=true"
					        readonly
					/>
					<van-field
					        v-model="option.isTechnicianName"
					        label="是否已分配"
					        placeholder="请选择是否分配配送员"
					        @focus="picker.isTechnician=true"
					        readonly
					/>
					<van-field
					        v-model="option.isReceivableName"
					        label="是否有应收"
					        placeholder="请选择是否有应收"
					        @focus="picker.isReceivable=true"
					        readonly
					/>
					<van-field
					        v-model="option.isPickUpName"
					        label="是否自提"
					        placeholder="请选择是否自提"
					        @focus="picker.isPickUp=true"
					        readonly
					/>
                    <van-button type="info" icon="passed" size="large" @click="onSearch"
                                style="position:fixed;bottom:0;"
                                text="查询"/>
                </van-cell-group>
            </van-popup>
            <!-- 配送日期 -->
            <van-popup v-model="picker.sendDate" position="bottom" :style="{height:'50%'}">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        cancel-button-text="清除"
                        @cancel="onClearApplyDate"
                        @confirm="onChangeApplyDate"
                />
            </van-popup>
			<!-- 订单状态 -->
			<van-popup v-model="picker.status" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="订单状态"
			            :columns="statusList"
			            @confirm="onChangeStatus"
			            @cancel="picker.status=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 订单类型 -->
			<van-popup v-model="picker.type" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="订单类型"
			            :columns="typeList"
			            @confirm="onChangeType"
			            @cancel="picker.type=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 城市 -->
			<van-popup v-model="picker.city" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="城市"
			            :columns="cityList"
			            @confirm="onChangeCity"
			            @cancel="picker.city=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 配送站 -->
			<van-popup v-model="picker.distribution" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送站"
			            :columns="distributionList"
			            @confirm="onChangeDistribution"
			            @cancel="picker.distribution=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 配送员 -->
			<van-popup v-model="picker.technician" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送员"
			            :columns="technicianList"
			            @confirm="onChangeTechnician"
			            @cancel="picker.technician=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 小区 -->
			<van-popup v-model="picker.smallArea" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="小区"
			            :columns="smallAreaList"
			            @confirm="onChangeSmallArea"
			            @cancel="picker.smallArea=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 是否分配配送员 -->
			<van-popup v-model="picker.isTechnician" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="是否分配配送员"
			            :columns="isTechnicianList"
			            @confirm="onChangeIsTechnician"
			            @cancel="picker.isTechnician=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 是否有应收 -->
			<van-popup v-model="picker.isReceivable" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="是否有应收"
			            :columns="isReceivableList"
			            @confirm="onChangeReceivable"
			            @cancel="picker.isReceivable=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 是否自提 -->
			<van-popup v-model="picker.isPickUp" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="是否自提"
			            :columns="isPickUpList"
			            @confirm="onChangePickUp"
			            @cancel="picker.isPickUp=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<van-popup v-model="picker.allocation" position="right" :round="true" :style="{width:'100%',height:'20%'}">
				<van-cell-group>
					<van-field v-model="order.distribution_technicia" label="配送员" placeholder="请选择配送员" style="margin-top: 2rem;"
						@focus="picker.technician2=true" readonly />
					<van-button type="info" icon="passed" size="large" @click="saveAllocation"
						style="position:fixed;bottom:0;" text="确定" />
				</van-cell-group>
			</van-popup>
			<van-popup v-model="picker.dispatch" position="right" :round="true" :style="{width:'100%',height:'20%'}">
				<van-cell-group>
					<van-field v-model="order.distribution_name" label="配送站" placeholder="请选择配送站" style="margin-top: 2rem;"
						@focus="picker.distribution2=true" readonly />
					<van-button type="info" icon="passed" size="large" @click="saveDispatch"
						style="position:fixed;bottom:0;" text="确定" />
				</van-cell-group>
			</van-popup>
			<!-- 配送站 -->
			<van-popup v-model="picker.distribution2" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送站"
			            :columns="distributionList2"
			            @confirm="onChangeDistribution2"
			            @cancel="picker.distribution2=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<!-- 配送员 -->
			<van-popup v-model="picker.technician2" position="bottom" :style="{height:'50%'}">
			    <van-picker
			            title="配送员"
			            :columns="technicianList2"
			            @confirm="onChangeTechnician2"
			            @cancel="picker.technician2=false"
			            :show-toolbar="true"
			    />
			</van-popup>
			<van-popup v-model="picker.show" position="bottom" :round="true" style="height: 4.5rem;">
				<div class="apply-detail-container-btn" style="line-height: 4rem;text-align: center;">
					<!-- 每个元素的两侧间隔相等 -->
					<van-row type="flex" justify="space-around">
						<van-col span="8">
							<van-button square type="info" icon="success" size="small" text="分配修改配送员" @click="onAllocation" />
						</van-col>
						<van-col span="8">
							<van-button square type="primary" icon="edit" size="small" text="分单调度配送站" @click="onDispatch" />
						</van-col>
					</van-row>
				</div>
			</van-popup>
            <!-- 订单分配详情 -->
            <van-popup v-model="picker.detail" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}" get-container="#app">
                <orderDetail :param.sync="param" :key="param.id" @onClose="onDelete" v-if="param.id"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import orderDetail from './detail'

    export default {
        name: "apply-search",
        components: {
            orderDetail
        },
        data() {
            return {
                freshing: false,
                auth: this.$store.state.user.auth || [],
                list: [],
				ids: [],
				order: {},
                isReceivableList: [
                   {index: '', text: '全部'},
                   {index: 1, text: '是'},
                   {index: 0, text: '否'}
                ],
                isPickUpList: [
                  {index: '', text: '全部'},
                  {index: 1, text: '是'},
                  {index: 0, text: '否'}
                ],
				isTechnicianList: [
				  {index: '', text: '全部'},
				  {index: 1, text: '是'},
				  {index: 0, text: '否'}
				],
				statusList: [
					{index: '', text: '全部'},
					{index: 1, text: '已确认'},
					{index: 2, text: '已生产'},
					{index: 3, text: '已入库'},
					{index: 4, text: '已上车'},
					{index: 5, text: '已下车'},
					{index: 6, text: '已出仓'}
				],
				typeList: [
					{index: '', text: '全部'},
					{index: 1, text: '正常订单'},
					{index: 2, text: '生日会蛋糕'},
					{index: 3, text: '市场推广'},
					{index: 4, text: '其他订单'},
					{index: 5, text: '补偿订单'},
					{index: 6, text: '测试订单'},
					{index: 7, text: '招待赠送'},
					{index: 8, text: '大客户订单'},
					{index: 9, text: '试吃订单'},
					{index: 10, text: '企业采集'},
					{index: 11, text: '月结订单'},
					{index: 12, text: '全国送'}
				],
                cityList: [],
                distributionList: [],
                technicianList: [],
				distributionList2: [],
				technicianList2: [],
				smallAreaList: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    id: '',
                    sendDate: '',
                    status: '',
                    statusName: '全部',
					isReceivable: '',
                    isReceivableName: '全部',
					isPickUp: '',
					isPickUpName: '全部',
					isTechnician: '',
					isTechnicianName: '全部',
					type: '',
					typeName: '全部',
					city: '',
					cityName: '全部',
					distribution: '',
					distributionName: '全部',
					technician: '',
					technicianName: '全部',
					smallArea: '',
					smallAreaName: '全部',
                },
                picker: {
                    search: false,
                    status: false,
                    sendDate: false,
                    isReceivable: false,
					isPickUp: false,
					isTechnician: false,
					type: false,
					technician: false,
					smallArea: false,
					city: false,
					distribution: false,
					detail: false,
					allocation: false,
					dispatch: false,
					technician2: false,
					distribution2: false,
					show: false
                },
                param: {id: 0},
                currentDate: new Date()
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.option.sendDate = this.nowDate(new Date());
                this.loadCity();
                this.onLoad();
            },
            loadCity() {
                let that = this;
                if (that.cityList.length == 0) {
                    that.$api.user.loadCity().then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            res.data.unshift({index: 0, text: '全部'});
                            that.cityList = res.data;
                        }
                    });
                }
            },
			onAll() {
				if (this.ids.length > 0) {
					this.picker.show = true;
				} else {
					return this.$toast.fail('请先选择要操作的订单');
				}
			},
			onBill(id) {
				if (this.ids.includes(id)) {
					let index = this.ids.indexOf(id);
					if (index > -1) {
					  this.ids.splice(index, 1);
					}
				} else {
					this.ids.push(id);
				}
			},
			onAllocation() {
				let that = this;
				if (!that.option.distribution) {
					return that.$toast.fail('请先选择配送站');
				}
				that.order.distribution = that.option.distribution;
				that.$api.user.loadCourier(that.option.distribution).then(response => {
				    let res = response.data;
				    if (res.code == 1 && res.data.length > 0) {
				        res.data.unshift({index: 0, text: '请选择'});
				        that.technicianList2 = res.data;
				    }
				});
				that.picker.allocation = true;
			},
			onDispatch() {
				let that = this;
				if (!that.option.city) {
					return that.$toast.fail('请先选择城市');
				}
				that.order.city = that.option.city;
				that.$api.user.loadSiteAll(that.option.city).then(response => {
				    let res = response.data;
				    if (res.code == 1 && res.data.length > 0) {
				        res.data.unshift({index: 0, text: '请选择'});
				        that.distributionList2 = res.data;
				    }
				});
				that.picker.dispatch = true;
			},
            onClearApplyDate() {
                this.option.sendDate = '';
                this.picker.sendDate = false;
            },
            onChangeApplyDate(value) {
                this.option.sendDate = this.nowDate(value);
                this.picker.sendDate = false;
            },
            onChangeStatus(value) {
                this.picker.status = false;
                this.option.status = value.index;
                this.option.statusName = value.text;
            },
            onChangeReceivable(value) {
                this.picker.isReceivable = false;
                this.option.isReceivable = value.index;
                this.option.isReceivableName = value.text;
            },
            onChangePickUp(value) {
                this.picker.isPickUp = false;
                this.option.isPickUp = value.index;
                this.option.isPickUpName = value.text;
            },
			onChangeTechnician(value) {
				this.picker.technician = false;
				this.option.technician = value.index;
				this.option.technicianName = value.text;
			},
			onChangeSmallArea(value) {
				this.picker.smallArea = false;
				this.option.smallArea = value.index;
				this.option.smallAreaName = value.text;
			},
			onChangeIsTechnician(value) {
				this.picker.isTechnician = false;
				this.option.isTechnician = value.index;
				this.option.isTechnicianName = value.text;
			},
			onChangeType(value) {
				this.picker.type = false;
				this.option.type = value.index;
				this.option.typeName = value.text;
			},
			// 分配修改保存
			saveAllocation() {
				let that = this;
				if (!that.order.distribution_technician_id) {
					return that.$toast.fail('请选择配送员');
				}
				that.$dialog.confirm({
				    message: '确认分配配送员为' + that.order.distribution_technicia + '吗？'
				}).then(() => {
				    that.order.ids = that.ids;
				    that.$api.allocation.allocation(that.order).then(response => {
				        let res = response.data;
				        if (res.code == 1) {
				            that.$toast.success(res.msg);
				            that.onLoad();
				    		that.picker.allocation = false;
				        } else {
				            that.$toast.fail(res.msg);
				        }
				    });
				});
			},
			// 分单调度保存
			saveDispatch() {
				let that = this;
				if (!that.order.distribution_id) {
					return that.$toast.fail('请选择配送站');
				}
				that.$dialog.confirm({
				    message: '确认分单调度到' + that.order.distribution_name + '吗？'
				}).then(() => {
				    that.order.ids = that.ids;
				    that.$api.allocation.dispatch(that.order).then(response => {
				        let res = response.data;
				        if (res.code == 1) {
				            that.$toast.success(res.msg);
				            that.onLoad();
				    		that.picker.dispatch = false;
				        } else {
				            that.$toast.fail(res.msg);
				        }
				    });
				});
			},
			onChangeTechnician2(value) {
				this.picker.technician2 = false;
				this.order.distribution_technician_id = value.index;
				this.order.distribution_technicia = value.text;
			},
			onChangeDistribution2(value) {
				this.picker.distribution2 = false;
				this.order.distribution_id = value.index;
				this.order.distribution_name = value.text;
			},
            onChangeCity(value) {
                // 清空配送站、配送员、小区
                if (this.option.city != value.index) {
                    this.option.distribution = '';
                    this.option.distributionName = '全部';
					this.option.technician = '';
					this.option.technicianName = '全部';
					this.option.smallArea = '';
					this.option.smallAreaName = '全部';
					this.distributionList = [];
					this.technicianList = [];
					this.smallAreaList = [];
                }
                this.picker.city = false;
                this.option.city = value.index;
                this.option.cityName = value.text;
				let that = this;
				that.$api.user.loadSite(that.option.city).then(response => {
				    let res = response.data;
				    if (res.code == 1 && res.data.length > 0) {
				        res.data.unshift({index: 0, text: '全部'});
				        that.distributionList = res.data;
				    }
				});
            },
			onChangeDistribution(value) {
                // 清空配送员、小区
                if (this.option.distribution != value.index) {
                    this.option.technician = '';
                    this.option.technicianName = '全部';
                    this.option.smallArea = '';
                    this.option.smallAreaName = '全部';
					this.technicianList = [];
					this.smallAreaList = [];
                }
                this.picker.distribution = false;
                this.option.distribution = value.index;
                this.option.distributionName = value.text;
				let that = this;
				that.$api.user.loadCourier(that.option.distribution).then(response => {
				    let res = response.data;
				    if (res.code == 1 && res.data.length > 0) {
				        res.data.unshift({index: 0, text: '全部'});
				        that.technicianList = res.data;
				    }
				});
				that.$api.user.loadArea(that.option.distribution).then(response => {
				    let res = response.data;
				    if (res.code == 1 && res.data.length > 0) {
				        res.data.unshift({index: 0, text: '全部'});
				        that.smallAreaList = res.data;
				    }
				});
            },
            onSearch() {
                this.option.page = 1;
                this.onLoad();
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
				that.picker.show = false;
				that.order = {};
				that.ids = [];
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.allocation.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            detail(item) {
                this.param.id = item.bill_no;
                this.picker.detail = true;
            },
            allocation(id) {
				let that = this;
				that.order.ids = [id];
				that.$api.allocation.detail(id).then(response => {
				    let res = response.data;
				    if (res.code == 1) {
						that.$api.user.loadCourier(that.order.distribution_id).then(response => {
						    let ret = response.data;
						    if (ret.code == 1 && ret.data.length > 0) {
						        ret.data.unshift({index: 0, text: '请选择'});
						        that.technicianList2 = ret.data;
						    }
						});
						that.picker.allocation = true;
				    } else {
				        that.$toast.fail(res.msg);
				    }
				});
            },
            dispatch(id) {
				let that = this;
				that.order.ids = [id];
				that.$api.allocation.detail(id).then(response => {
					let res = response.data;
				    if (res.code == 1) {
						that.$api.user.loadSite(that.order.deliver_cityid).then(response => {
						    let res = response.data;
						    if (res.code == 1 && res.data.length > 0) {
						        res.data.unshift({index: 0, text: '请选择'});
						        that.distributionList2 = res.data;
						    }
						});
						that.picker.dispatch = true;
				    } else {
				        that.$toast.fail(res.msg);
				    }
				});
            },
            onDelete() {
                this.picker.detail = false;
                this.onLoad();
            },
            nowDate(now) {
                let date = now ? now : new Date();
                let seperator = '-';
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = '0' + month;
                }
                if (day >= 0 && day <= 9) {
                    day = '0' + day;
                }
                return year + seperator + month + seperator + day;
            }
        }
    }
</script>

<style scoped>
    .order-search-container {
        padding-top: 46px;
    }

    .van-cell__title {
        flex: 3
    }

    .van-cell__title .van-tag {
        margin-right: 4px;
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .order-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .order-search-container-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .order-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }
</style>
